$cdn-url: 'https://cdn.mainst.io';
// Import Tailwind files
@tailwind base;
@tailwind components;

// Import Dropzone styles
@import "../dropzone/dropzone";
// Import Progressive Image styles
@import "../progressive-image/progressive-image";
// Import Platform variables
@import "variables";
// Import Toast styles
@import "~vue-toastification/src/scss/index";
// Import Redactor styles
@import "~@craftcodery/redactor/scss/redactor";
// Import Platform specific files
@import "global";
@import "components/datepicker";
@import "components/kanban";
@import "components/loading";
@import "components/multiselect";
@import "components/redactor";
@import "components/slider";
@import "components/tooltip";

@tailwind utilities;
